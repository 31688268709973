<template>
  <div class="table-div">
      <div class="title">
        <div class="left">{{titleName}}</div>
        <div class="right"> {{$core.formatDate(new Date(),'yyyy-MM-dd '+'00:00:00 更新')}}</div>
      </div>
      <div class="table">
        <template v-if="spliceList && spliceList.length > 0">
          <div class="item" v-for="(item,index) in spliceList" :key="index">
            <template>
              <div class="left"><span :class="index+1 <= 3 ? 'span first' : 'span' ">{{index + 1}}</span></div>
              <div class="center">{{item.title}}</div>
              <div class="right">{{item.num}}</div>
            </template>
          </div>
        </template>
        <div v-else class="table-none">
          <div class="title">暂无数据</div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props:['titleName','tableList'],
  computed:{
    spliceList:function(){
      //  if(this.tableList && this.tableList.length > 10){
         return this.tableList.splice(0,10)
      //  }else{/
         return this.tableList
      //  }
    }
  }
}
</script>

<style lang="less" scoped>
.table-div{
  width: 100%;
  height: 100%;
  .title{
    display: flex;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;

  }
  .table{
    height: calc(100% - 40px);
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    .item{
      display: flex;
      padding: 12px;
      border-bottom: 1px solid #ccc;
      width: 100%;
      &:nth-last-child(1){
        border-bottom: none;
      }
      .left{
        width: 10%;
        .span{
          width: 20px;
          height: 20px;
          display: block;
          text-align: center;
          color: #ffffff;
          background-color: rgb(240, 173, 78);
          border-radius: 5px;
        }
        .first{
          background-color: rgb(217, 83, 79)
        }
      }
      .center{
        flex: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right{
        margin-left: auto;
        widows: 15%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-none{
      height: 100%;
      display: table;
      margin: 0 auto;
      color: #666;
      .title{
        height: 100%;
        vertical-align: middle;
        display: table-cell;
      }
    }
  }
}
</style>